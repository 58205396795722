import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-general"},[_c(VSnackbar,{attrs:{"top":"","light":_vm.$vuetify.theme.dark},model:{value:(_vm.isRoundedSnackbarVisible),callback:function ($$v) {_vm.isRoundedSnackbarVisible=$$v},expression:"isRoundedSnackbarVisible"}},[_vm._v(" Algo deu errado ao pesquisar cliente ")]),_c('div',{staticClass:"head"},[_c(VImg,{attrs:{"width":"30px","src":require("@/assets/images/avatars/lupa.png")}}),_c('h1',[_vm._v("Encontre informações dos clientes em um so clique!")]),_c('span',[_vm._v("Informações como: Contato, e-mail, endereço, data de nascimento, etc.")])],1),_c('div',{staticClass:"container-search"},[_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"label":"Digite um CPF ou CNPJ","outlined":"","hide-details":"","dense":""},model:{value:(_vm.cpfOrCnpj),callback:function ($$v) {_vm.cpfOrCnpj=$$v},expression:"cpfOrCnpj"}})],1),_c(VBtn,{staticClass:"button-access",attrs:{"color":"primary"},on:{"click":_vm.toSearch}},[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiMagnify)+" ")]),(!_vm.isLoading)?_c('span',[_vm._v("PESQUISAR")]):_c(VProgressCircular,{attrs:{"color":"white","indeterminate":""}})],1)],1),_vm._m(0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-foot"},[_c('img',{attrs:{"src":require("../../../../assets/female-search.svg"),"width":"300px"}}),_c('img',{staticClass:"tree",attrs:{"src":require("../../../../assets/tree.svg"),"width":"150px"}})])}]

export { render, staticRenderFns }