<template>
  <div class="container-general">
    <v-snackbar
      v-model="isRoundedSnackbarVisible"
      top
      :light="$vuetify.theme.dark"
    >
      Algo deu errado ao pesquisar cliente
    </v-snackbar>
    <div class="head">
      <v-img
        width="30px"
        :src="require(`@/assets/images/avatars/lupa.png`)"
      />

      <h1>Encontre informações dos clientes em um so clique!</h1>

      <span>Informações como: Contato, e-mail, endereço, data de nascimento, etc.</span>
    </div>

    <div class="container-search">
      <v-col cols="4">
        <v-text-field
          v-model="cpfOrCnpj"
          label="Digite um CPF ou CNPJ"
          outlined
          hide-details
          dense
          class=""
        >
        </v-text-field>
      </v-col>

      <v-btn
        color="primary"
        class="button-access"
        @click="toSearch"
      >
        <v-icon
          size="20"
        >
          {{ icons.mdiMagnify }}
        </v-icon>
        <span v-if="!isLoading">PESQUISAR</span>
        <v-progress-circular
          v-else
          color="white"
          indeterminate
        >
        </v-progress-circular>
      </v-btn>
    </div>

    <div class="container-foot">
      <img
        src="../../../../assets/female-search.svg"
        width="300px"
      />

      <!-- <span>dadadadasd</span> -->

      <img
        src="../../../../assets/tree.svg"
        width="150px"
        class="tree"
      />
    </div>
  </div>
</template>
<script>
import { mdiMagnify } from '@mdi/js'
import axios from 'axios'
import localStorageSlim from 'localstorage-slim'

export default {
  data() {
    return {
      icons: {
        mdiMagnify,
      },
      isLoading: false,
      isRoundedSnackbarVisible: false,
      cpfOrCnpj: '',
    }
  },
  created() {
    localStorageSlim.remove('dataClientNVTI')
    localStorageSlim.remove('documentNVTI')
  },
  methods: {
    async toSearch() {
      this.isLoading = true

      const headers = {
        'Content-Type': 'application/json',
        Token: localStorageSlim.get('tokenNVTI', { decrypt: true }),
        Accept: 'application/json',
      }

      try {
        const response = await axios.post('https://wsnv.novavidati.com.br/wslocalizador.asmx/NVCHECKJson', {
          nvcheck: {
            Documento: this.cpfOrCnpj,
          },
        }, { headers })

        localStorageSlim.set('dataClientNVTI', response.data.d.CONSULTA, { encrypt: true })
        localStorageSlim.set('documentNVTI', this.cpfOrCnpj, { encrypt: true })

        await this.$router.push({ name: 'explorer-list' })
      } catch (error) {
        console.log(error)

        this.isRoundedSnackbarVisible = true
      } finally {
        this.isLoading = false
      }

      // this.$router.go()
    },
  },
}
</script>

<style scoped>
.container-general{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.head{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container-search{
  margin-top: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.container-foot{
  position: relative;
  flex-grow: 1;
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.tree{
  position: absolute;
  bottom: -30px;
  right: 0
}
</style>
